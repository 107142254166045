* {
  box-sizing: border-box;
}
html,
body {
  max-width: 100%;
}
body {
  margin: 0;
  background-image: url(./bg.png);
  background-size: 1069px 712px;
  background-color: #eaeaeb;
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #eaeaeb;
  overflow-x: hidden;
}
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
}
a.nav-link {
  position: relative;
  color: transparent;
  opacity: 0.5;
}
a.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #fa00ff;
  bottom: -26px;
  left: 0;
  opacity: 0;
}
a.nav-link.active {
  opacity: 1;
}
a.nav-link.active::after {
  opacity: 1;
}

pre {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  height: 531px;
  overflow: auto;
  position: relative;
  border: 1px solid #eff2fd;
}
.string {
  color: #a31515;
}
.number {
  color: #0a8658;
}
.boolean,
.null {
  color: #0000ff;
}
.key {
  color: #0352a5;
}
.back-link {
  text-decoration: none;
}
